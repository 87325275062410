import React from "react";
import { withRouter } from "react-router-dom";
import "./services.css";
import Navbar from "../../components/NavBar/index";
import Footer from "../../components/Footer/footer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import land_3 from "../../assets/images/land_3.jpg";
import land_4 from "../../assets/images/land_4.jpg";
import land_5 from "../../assets/images/land_5.jpg";
import land_6 from "../../assets/images/land_6.jpg";
import { Link } from "react-router-dom";
import {
  FloatingMenu,
  MainButton,
  ChildButton,
} from 'react-floating-button-menu';
import { BsPlusLg, BsWhatsapp} from "react-icons/bs";
import { MdClose } from "react-icons/md";

const slideImages = [
  {
    url: land_3,
  },
  {
    url: land_4,
  },
  {
    url: land_5,
  },
  {
    url: land_6,
  },
];

class services extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFABOpen: false
    };
  }
  componentDidMount(){
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }
  render() {
    return (
    <div>
      <Navbar />
      <div className="fab-btn-hvr">
        <FloatingMenu
          slideSpeed={500}
          direction="up"
          spacing={8}
          isOpen={this.state.isFABOpen}
        >
          <MainButton
            iconResting={<BsPlusLg size={22}/>}
            iconActive={<MdClose size={28} className="GrClose-icons"/>}
            style={{backgroundColor:"#00476b"}}
            onClick={() => this.setState({ isFABOpen: !this.state.isFABOpen })}
            size={56}
          />
          <ChildButton
            icon={<BsWhatsapp size={20} className="BsWhatsapp-icons" />}
            style={{backgroundColor:"#00476b"}}
            size={40}
            onClick={() => window.open("https://wa.me/917249556789?text=Hello", '_blank')}
          />
        </FloatingMenu>
      </div>
      <div className="services-container">
        <Row>
          <Col md={8} sm={12} xs={12}>
              <div className="services-top-div">
                  <p className="services-top-title">Visit Your Site Virtually.</p>
                  <p className="services-Paragraph">
                      MYPLOTPIC.COM is a successful agency that has been operating for
                      seven years. We have more than 50 successful projects on our list,
                      and the number keeps growing with more signed contracts and
                      satisfied clients. We also provide other services such as
                      constructing a wall or fence and painting your real estate on your
                      demand.
                  </p>
                  <div className="align-items">
                    <Link to="/create-order">
                      <button className="services-btn">
                          Book Now
                      </button>
                    </Link>
                  </div>
              </div>
          </Col>
          <Col md={4} sm={12} xs={12}>
            <Slide arrows={false} duration={2700}>
              {slideImages.map((slideImage, index)=> (
                <img className="services-img-slider" alt="slider images" src={slideImage.url} />
                ))} 
            </Slide>
          </Col>
        </Row>
        <div className="services-div-margins">
          <p className="services-top-title">Virtual Visit to the open Plot/Land.</p>
          <p className="services-Paragraph">Our field executives visit the subscriber’s properties and shoot
            live images of developments, if any, surrounding the property. We
            take a video of the location to capture the real-time ground
            position. On a prior appointment with the property owner, we
            activate a virtual visit of the property to the owner, assuring the
            safe possession of the property. At owners’ cost, we shall erect
            fencing around the property or a temporary shed, paint it capturing
            the plot no or survey number in case of agricultural land, and place
            a notice board and maintain and re-paint when it is faded.</p>
          <p className="services-Paragraph">Periodically, we notify the owner regarding the market value of
            their property. If you intend to dispose-off the property to
            acquaintances or through property brokers, we accompany them to the
            location and share the images and video clips that have already been
            shared with the property owner. However, this go-to action would
            take place only after receiving written authorization from the
            property owner.</p>
          <div className="align-items">
            <Link to="/create-order">
              <button className="services-btn">
                Order Now
              </button>
            </Link>
          </div>
        </div>
        <div className="services-div-margins">
          <p className="services-top-title">Apartment Progress.</p>
          <p className="services-Paragraph">To monitor your builders on whole apartment construction
            irrespective of the construction range, our team visits the location
            to inspect the progress of your apartment work. Our skilled workers
            inspect your building from exterior to interior.</p>
          <div className="align-items">
            <Link to="/create-order">
              <button className="services-btn">
                Order Now
              </button>
            </Link>
          </div>
        </div>
        <div className="services-div-margins">
          <p className="services-top-title">Rental Property Visit</p>
          <p className="services-Paragraph">In case you are located away from where your property is let out to
            the tenant. We will help you know the physical status of your
            property such as; if the place is maintained well by the tenant or
            if the tenant is paying fair rent.</p>
          <p className="services-Paragraph">As a third party, we present the current status of the property and
            neighboring rental amount, etc. We will give you a 360-degree view
            of your property, enabling you to have a virtual look. We recommend
            you to look at your property once a year physically or virtually.
            This service depends on the permission and appointment given by the
            landowner and tenant. We also help you post for sale let out for
            your property on the online sites.</p>
          <div className="align-items">
            <Link to="/create-order">
              <button className="services-btn">
                Order Now
              </button>
            </Link>
          </div>
        </div>
        <div className="services-div-margins">
          <p className="services-top-title">Other Services</p>
          <p className="services-Paragraph">We are also available for other services, which enable us to
            construct a wall, fence or paint your real estate on your demand.</p>
          <div className="align-items">
            <Link to="/create-order">
              <button className="services-btn">
                Order Now
              </button>
            </Link>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
    
    );
  }
}

export default withRouter(services);
