import React from 'react';

import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailIcon,EmailShareButton
} from 'react-share';

const Share = ({ baseImageUrl }) => {
  const shareUrl = baseImageUrl;

  return (
    <div
      style={{
        background: '#0000',
     
        width: '100%',
      }}
    >
     <h3>Share the joy with a click!</h3>


      <EmailShareButton
        url={shareUrl}
        quote={'Check out this awesome content!'}
        hashtag={'#AwesomeContent'}
      >
        <EmailIcon size={40} round={true} />
      </EmailShareButton>


      <FacebookShareButton
        url={shareUrl}
        quote={'Check out this awesome content!'}
        hashtag={'#AwesomeContent'}
      >
        <FacebookIcon size={40} round={true} />
      </FacebookShareButton>

      <WhatsappShareButton
        url={shareUrl}
        quote={'Check out this awesome content!'}
        hashtag={'#AwesomeContent'}
      >
        <WhatsappIcon size={40} round={true} />
      </WhatsappShareButton>

      <TwitterShareButton
        url={shareUrl}
        quote={'Check out this awesome content!'}
        hashtag={'#AwesomeContent'}
      >
        <TwitterIcon size={40} round={true} />
      </TwitterShareButton>
    </div>
  );
};

export default Share;
