import React from "react";
import { withRouter } from "react-router-dom";
import {
  FloatingMenu,
  MainButton,
  ChildButton,
} from 'react-floating-button-menu';
import { BsPlusLg, BsWhatsapp} from "react-icons/bs";
import { MdClose } from "react-icons/md";

import "./about_us.css";
import Footer from "../../components/Footer/footer";
import Navbar from "../../components/NavBar/index";


class about_us extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFABOpen: false
    };
  }
  componentDidMount(){
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }
  render() {
    return (
    <div>
      <Navbar />
      <div className="container">
        <div className="fab-btn-hvr">
            <FloatingMenu
              slideSpeed={500}
              direction="up"
              spacing={8}
              isOpen={this.state.isFABOpen}
            >
              <MainButton
                iconResting={<BsPlusLg size={22}/>}
                iconActive={<MdClose size={28} className="GrClose-icons"/>}
                style={{backgroundColor:"#00476b"}}
                onClick={() => this.setState({ isFABOpen: !this.state.isFABOpen })}
                size={56}
              />
              <ChildButton
                icon={<BsWhatsapp size={20} className="BsWhatsapp-icons" />}
                style={{backgroundColor:"#00476b"}}
                size={40}
                onClick={() => window.open("https://wa.me/917249556789?text=Hello", '_blank')}
              />
            </FloatingMenu>
          </div>
        <div className="new-bx">
            <p className="text_Title">Our Goal</p>
            <div className="text_subHeader">
              Our goal is to provide high-quality services to our customers with
              better experience through virtual visits to their properties by
              saving their valuable money and time. We should get a call whenever
              customer wants to invest or monitor their plot or Flat.
            </div>
            <div className="text_subTitle">
                MYPLOTPIC.COM is a rising one on the market. Working with both companies and
                individuals, we strive to offer the best possible conditions for
                visiting sites and providing videos of real estate. We operate in
                all manners of real estate fields ranging from sites visit to
                apartment’s progress and anything in between. In a word, we can help
                you by giving site visit to any kind of real estate. Working with
                many customers, we have the experience, knowledge, and skills to
                meet and even exceed your expectations. Our biggest goal is to
                provide high-quality services to our customers and contribute to
                maintaining a quality real estate market.
            </div>
        </div>
      </div>
      <Footer/>
    </div>
    
    );
  }
}

export default withRouter(about_us);
