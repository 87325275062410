import React from "react";
import { withRouter } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Select from "react-select";
import "./register.css";
import Footer from "../../components/Footer/footer";
import registerImg from "../../assets/registerImg.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import {
  FloatingMenu,
  MainButton,
  ChildButton,
} from 'react-floating-button-menu';
import { BsPlusLg, BsWhatsapp} from "react-icons/bs";
import { MdClose } from "react-icons/md";
import Navbar from "../../components/NavBar/index";

const dailOptions = [
  { value: "+91", label: "+91" },
  { value: "+973", label: "+973" },
  { value: "+971", label: "+971" },
  { value: "+974", label: "+971" },
];
const customStyles = {
  control: (base) => ({
    ...base,
    height: 43,
    minHeight: 43,
  }),
};

class register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: null,
      email: null,
      phoneNumber: "",
      password: null,
      dialCode: "+91",
      errors: {},
      isCheckValided: false,
      registerView: true,
      isFABOpen: false
    };
    this.handleName = this.handleName.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handleDail = this.handleDail.bind(this);
    this.handlePhone = this.handlePhone.bind(this);
    this.toastFunct = this.toastFunct.bind(this);
    this.successToast = this.successToast.bind(this);
    this.errorToast = this.errorToast.bind(this);
  }
  toastFunct() {
    toast.info("Loading ..", {
      position: "bottom-center",
      autoClose: 1200,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  successToast() {
    toast.success("Account Created", {
      position: "bottom-center",
      autoClose: 2700,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  errorToast() {
    toast.error("Account Already Exists", {
      position: "bottom-center",
      autoClose: 2700,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  // HANDLE NAME INPUT
  handleName(event) {
    let errors = this.state.errors;
    let name = event.target.value.length
    if (name>4) {
      errors["name"] = null
      this.setState({ errors: errors});
    }
     else {
      errors["name"] = "Must be at least 5 characters";
      this.setState({ errors: errors});
    }
    this.setState({
      name: event.target.value,
    });
  }

  // HANDLE EMAIL INPUT
  handleEmail(event) {
    var re = /\S+@\S+\.\S+/;
    var result = re.test(event.target.value);
    let errors = this.state.errors;
    if(re.test(event.target.value))
    {
      errors["email"] = null
      this.setState({ errors: errors});
      //console.log(result)
    }
    else{
      errors["email"] = "Invalid Email";
      this.setState({ errors: errors });
    }
    this.setState({
      email: event.target.value,
    });
  }

  // HANDLE PASSWORD INPUT
  handlePassword(event) {
    let errors = this.state.errors;
    let password = event.target.value.length
    if (password>7) {
      errors["password"] = null
      this.setState({ errors: errors});
    }
     else {
      errors["password"] = "Must be at least 8 characters";
      this.setState({ errors: errors});
    }
    this.setState({
      password: event.target.value,
    });
  }

  // HANDLE DIAL CODE
  handleDail = (dialCode) => {
    this.setState({ dialCode: dialCode.value });
  };

  handlePhone(object) {
    if (object.target.value.length <= object.target.maxLength) {
      this.setState({ phoneNumber: object.target.value });
    }
    let errors = this.state.errors;
    var phoneLength = object.target.value.length;
    if (phoneLength === 11 || phoneLength === 10 ) {
      errors["phone"] = null
      this.setState({ errors: errors});
    }
     else {
      errors["phone"] = "Must be 10 digits";
      this.setState({ errors: errors});
    }
  }

  // SIGNUP API CALL
  registerAccount = async () => {
    const {name, email, phoneNumber, password, errors} = this.state;
    let emailWarning = this.state.errors["email"];
    let nameWarning = this.state.errors["name"];
    let phoneWarning = this.state.errors["phone"];
    let passwordWarning = this.state.errors["password"];
    if(name != "" && email != "" && phoneNumber != "" && password != ""){
      if(emailWarning == null && nameWarning == null && phoneWarning == null && passwordWarning == null)
      {
        this.toastFunct();
        let userDetails = {
          name: name,
          contactno: phoneNumber,
          adhar: "",
          address: "India",
          email: email,
          password: password,
          role: 0
        };
        let ld = JSON.stringify(userDetails);
        //console.log(ld);
        await fetch(
          "https://backend.myplotpic.com/api/signup",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: ld,
          }
        )
          .then((response) => response.json())
          .then((responseJson) => {
            //console.log(responseJson);
            if (responseJson._id) {
              this.successToast();
              //console.log(responseJson)
              this.storeData(responseJson);
              this.getToken();
              this.setState({ registerView: false });
            }
            else if(responseJson.error === "email or mobile number is already exist"){
              this.errorToast();
            }
            else {
              this.successToast();
              this.storeData(responseJson);
              this.getToken();
              this.setState({ registerView: false });
            }
          }
        );
      }
    }
    else{
      //console.log("Form Invalid")
    }
  }
  storeData = async (userData) => {
    // const { userData } = this.state;
    localStorage.setItem("userData", JSON.stringify(userData));
    //console.log("<=====UserData Saved=====>");
  };
  getToken = async () => {
    let loginDetails = {
      email: this.state.email,
      password: this.state.password,
      contactno: this.state.phoneNumber
    };
    let ld = JSON.stringify(loginDetails);
    //console.log("Login Details ====> " + ld);
    await fetch("https://backend.myplotpic.com/api/signin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: ld,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        //console.log(responseJson);
        if (responseJson.message === "Authentication error.") {
          //console.log("error")
        }
        else if(responseJson.message === "Account does not exist"){
          //console.log("error")
        }
         else {
          this.storeToken(responseJson.token);
        }
      });
  }
  storeToken = async (token) => {
    localStorage.setItem("token", JSON.stringify(token));
    //console.log("<=====Token Saved=====>");
  };
  render() {
    const { dialCode } = this.state;
    return (
    <div>
      <Navbar />
      <div className="fab-btn-hvr">
        <FloatingMenu
          slideSpeed={500}
          direction="up"
          spacing={8}
          isOpen={this.state.isFABOpen}
        >
          <MainButton
            iconResting={<BsPlusLg size={22}/>}
            iconActive={<MdClose size={28} className="GrClose-icons"/>}
            style={{backgroundColor:"#00476b"}}
            onClick={() => this.setState({ isFABOpen: !this.state.isFABOpen })}
            size={56}
          />
          <ChildButton
            icon={<BsWhatsapp size={20} className="BsWhatsapp-icons" />}
            style={{backgroundColor:"#00476b"}}
            size={40}
            onClick={() => window.open("https://wa.me/917249556789?text=Hello", '_blank')}
          />
        </FloatingMenu>
      </div>
      <div className="registrationContainer">
        <Row>
            <Col md={6}>
              {
                this.state.registerView?
                  <div className="secondDiv">
                    <p className="register_txt">Registration</p>
                    <div className="register_hr"></div>
                    <p className="register_subtxt">Full Name</p>
                    <input
                      className="registerInput"
                      type="text"
                      placeholder="Name"
                      value={this.state.name}
                      onChange={this.handleName}
                      onFocus={this.handleName}
                    ></input>
                    {this.state.errors["name"] ? (
                      <span
                        className="errorText_register"
                      >
                        {this.state.errors["name"]}
                      </span>
                    ) : (
                      <div className="registerInputMargin"></div>
                    )}
                    <p className="register_subtxt2">Email</p>
                    <input
                      className="registerInput"
                      type="text"
                      placeholder="Email"
                      value={this.state.email}
                      onChange={this.handleEmail}
                      onFocus={this.handleEmail}
                    ></input>
                    {this.state.errors["email"] ? (
                      <span
                        className="errorText_register"
                      >
                        {this.state.errors["email"]}
                      </span>
                    ) : (
                      <div className="registerInputMargin"></div>
                    )}
                    <p className="register_subtxt2">Password</p>
                    <input
                      className="registerInput"
                      type="password"
                      placeholder="Password"
                      value={this.state.password}
                      onChange={this.handlePassword}
                      onFocus={this.handlePassword}
                    ></input>
                    {this.state.errors["password"] ? (
                      <span
                        className="errorText_register"
                      >
                        {this.state.errors["password"]}
                      </span>
                    ) : (
                      <div className="registerInputMargin"></div>
                    )}
                    <p className="register_subtxt2">Phone Number</p>
                    <Row className="registerInputMargin registerDailDiv">
                      <Col md={3} xs={5} sm={5}>
                        <Select
                          onChange={this.handleDail}
                          value={dailOptions.find(
                            (item) => item.value === dialCode
                          )}
                          defaultValue={{ value: "+91", label: "+91" }}
                          options={dailOptions}
                          placeholder={<div>Dial</div>}
                          isSearchable={false}
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              height: 45,
                              marginLeft: -4
                            })
                          }}
                        />
                      </Col>
                      <Col md={9} xs={7} sm={7}>
                        <input
                          className="registerInput"
                          type = "number" maxLength = "10"
                          placeholder="Phone Number"
                          value={this.state.phoneNumber}
                          onChange={this.handlePhone}
                          onFocus={this.handlePhone}
                        ></input>
                        {this.state.errors["phone"] ? (
                          <span
                            className="errorText_register"
                          >
                            {this.state.errors["phone"]}
                          </span>
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                    <center>
                      <button
                        style={{
                          borderRadius: 10,
                          backgroundColor: "#00476b",
                          marginTop:"5%",
                          borderRadius: 10,
                          width: "100%",
                          marginTop:"1%",
                          marginBottom: "12%",
                          height:45
                        }}
                        onClick={this.registerAccount}
                      >
                        <span className="register_btn-txt">Create your account</span>
                      </button>
                    </center>
                  </div>
                :
                <div className="secondDiv">
                  <p className="almostThere_txt">Almost there!</p>
                  <p className="subRegisterTitle">Your account has been created successfully. Kindly verify the details </p>
                  <center>
                    <Link to="/verify-email">
                      <button className="verifyRegister-btn"><span> Verify Email</span></button>
                    </Link>
                    <br/>
                    <Link to="/verify-phone">
                      <button className="verifyRegister-btn"><span> Verify Phone</span></button>
                    </Link>
                  </center>
                  
                </div>
              }
            </Col>
            <Col md={6} className="firstDiv">
              <center>
                  <img
                      alt="underline"
                      src={registerImg}
                      className="firstDiv-img"
                  />
                </center>
            </Col>
        </Row>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Footer/>
    </div>
    
    );
  }
}

export default withRouter(register);
