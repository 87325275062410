import React from "react";
import { withRouter } from "react-router-dom";
import "./contact.css";
import bgCover from "../../assets/images/cchm.png";
import Footer from "../../components/Footer/footer";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ToastContainer, toast } from "react-toastify";
import {
  FloatingMenu,
  MainButton,
  ChildButton,
} from 'react-floating-button-menu';
import { BsPlusLg, BsWhatsapp} from "react-icons/bs";
import { MdClose } from "react-icons/md";

import "react-toastify/dist/ReactToastify.css";
import Navbar from "../../components/NavBar/index";

class contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "", 
      phone: "",
      message: "",
      errors: {},
      isCheckValided: false,
      isFABOpen: false
    }
    this.handleEmail = this.handleEmail.bind(this);
    this.handleName = this.handleName.bind(this);
    this.handlePhone = this.handlePhone.bind(this);
    this.handleMessage = this.handleMessage.bind(this);
    this.toastFunct = this.toastFunct.bind(this);
    this.successToast = this.successToast.bind(this);
    this.submitCheck = this.submitCheck.bind(this);
  }
  handleName(event) {
    let errors = this.state.errors;
    let name = event.target.value.length
    if (name>4) {
      errors["name"] = null
      this.setState({ errors: errors});
    }
     else {
      errors["name"] = "Must be at least 5 characters";
      this.setState({ errors: errors});
    }
    this.setState({
      name: event.target.value,
    });
    this.submitCheck()
  }
  handleEmail(event) {
    var re = /\S+@\S+\.\S+/;
    var result = re.test(event.target.value);
    let errors = this.state.errors;
    if(re.test(event.target.value))
    {
      errors["email"] = null
      this.setState({ errors: errors});
      //console.log(result)
    }
    else{
      errors["email"] = "Invalid Email";
      this.setState({ errors: errors });
    }
    this.setState({
      email: event.target.value,
    });
    this.submitCheck()
  }
  handlePhone(object) {
    if (object.target.value.length <= object.target.maxLength) {
      this.setState({ phone: object.target.value });
    }
    let errors = this.state.errors;
    var phoneLength = object.target.value.length;
    if (phoneLength === 11 || phoneLength === 10 ) {
      errors["phone"] = null
      this.setState({ errors: errors});
    }
     else {
      errors["phone"] = "Must be 10 digits";
      this.setState({ errors: errors});
    }
    this.submitCheck()
  }
  handleMessage(event) {
    let errors = this.state.errors;
    let message = event.target.value.length
    if (message>14) {
      errors["message"] = null
      this.setState({ errors: errors});
    }
     else {
      errors["message"] = "Must be at least 15 characters";
      this.setState({ errors: errors});
    }
    this.setState({ message: event.target.value });
    this.submitCheck()
  }
  submitCheck = () => {
    const {name, email, phone, message, errors} = this.state;
    let nameWarning = this.state.errors["name"];
    let emailWarning = this.state.errors["email"];
    let phoneWarning = this.state.errors["phone"];
    let messageWarning = this.state.errors["message"];
    if(name != "" && email != "" && phone != "" && message != ""){
      if(nameWarning == null && emailWarning == null && messageWarning == null && phoneWarning == null)
      {
        this.setState({
          isCheckValided: true
        })
      }
      else{
        this.setState({
          isCheckValided: false
        })
      }
    }
    else{
      this.setState({
        isCheckValided: false
      })
    }
  }
  toastFunct() {
    toast.info("Sending ..", {
      position: "bottom-center",
      autoClose: 1200,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  successToast() {
    toast.success("Inquiry submitted successfully", {
      position: "bottom-center",
      autoClose: 2700,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  sendContact = async () => {
    this.toastFunct();
    let passwordDetails = {
      name: this.state.name,
      mail: this.state.email,
      message: this.state.message,
      phone: this.state.phone,
    };
    let ld = JSON.stringify(passwordDetails);
    //console.log(ld)
    await fetch("https://backend.myplotpic.com/api/addEnquiry", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: ld,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          //console.log(responseJson);
          if (responseJson.message === "Inquiry submitted successfully") {
            this.setState({
              name: "",
              email: "",
              message: "",
              phone: ""
            })
            this.successToast();
          }
      });
  }
  componentDidMount(){
    var userData = JSON.parse(localStorage.getItem("userData"));
    if(userData){
      this.setState({
        email: userData.email
      })
    }
  }
  render() {
    return (
    <div>
      <Navbar />
        <div className="fab-btn-hvr">
            <FloatingMenu
              slideSpeed={500}
              direction="up"
              spacing={8}
              isOpen={this.state.isFABOpen}
            >
              <MainButton
                iconResting={<BsPlusLg size={22}/>}
                iconActive={<MdClose size={28} className="GrClose-icons"/>}
                style={{backgroundColor:"#00476b"}}
                onClick={() => this.setState({ isFABOpen: !this.state.isFABOpen })}
                size={56}
              />
              <ChildButton
                icon={<BsWhatsapp size={20} className="BsWhatsapp-icons" />}
                style={{backgroundColor:"#00476b"}}
                size={40}
                onClick={() => window.open("https://wa.me/917249556789?text=Hello", '_blank')}
              />
            </FloatingMenu>
          </div>
      <div className="content-box">
      <Row>
          <Col sm={12} lg={6} md={6}>
            <div className="container-ct-frm-drk" style={{marginTop:"10%"}}>
              <p className="the-title_contactUs">Contact Us</p>
              <div className="cont-text">
                <p className="txt-content">
                  Thank you for your interest in MyPlotPic! Please submit your
                  contact request using the following form and a member of our team
                  will reach out to you shortly.
                </p>
              </div>
              <div className="form-contact">
                  <div className="form-container">
                    <p className="new_ct-frm-lbl">Name :</p>
                    <input
                      className="input-style input_contact"
                      type="text"
                      placeholder="John Doe"
                      value={this.state.name}
                      onChange={this.handleName}
                      onFocus={this.handleName}
                      onBlur={this.submitCheck}
                    />
                    {
                      this.state.errors["name"] ? (
                        <span
                          className="errorText_ContactUs"
                        >
                          {this.state.errors["name"]}
                        </span>
                      ) : (
                        <></>
                      )
                    }
                    <p className="new_ct-frm-lbl">Email :</p>
                    <input
                      className="input-style input_contact"
                      type="text"
                      placeholder="random@sample.com"
                      value={this.state.email}
                      onFocus={this.handleEmail}
                      onBlur={this.submitCheck}
                      onChange={this.handleEmail}
                    />
                    {
                      this.state.errors["email"] ? (
                        <span
                          className="errorText_ContactUs"
                        >
                          {this.state.errors["email"]}
                        </span>
                      ) : (
                        <></>
                      )
                    }
                    <p className="new_ct-frm-lbl">Phone :</p>
                    <input
                      className="input-style input_contact"
                      placeholder="Phone Number"
                      onChange={this.handlePhone}
                      onBlur={this.submitCheck}
                      onFocus={this.handlePhone}
                      value={this.state.phone}
                      type = "number" maxLength = "10"
                    />
                    {
                      this.state.errors["phone"] ? (
                        <span
                          className="errorText_ContactUs"
                        >
                          {this.state.errors["phone"]}
                        </span>
                      ) : (
                        <></>
                      )
                    }
                    <p className="new_ct-frm-lbl">Message :</p>
                    <input
                      className="input-style messageInput"
                      type="text"
                      placeholder="Message"
                      value={this.state.message}
                      onChange={this.handleMessage}
                      onFocus={this.handleMessage}
                      onBlur={this.submitCheck}
                    />
                    {
                      this.state.errors["message"] ? (
                        <span
                          className="errorText_ContactUs"
                        >
                          {this.state.errors["message"]}
                        </span>
                      ) : (
                        <></>
                      )
                    }
                  </div>
                  <center>
                    {this.state.isCheckValided ? (
                      <button
                        style={{
                          borderRadius: 60,
                          width: 120,
                          marginTop:"9%",
                          marginBottom: "3%",
                          backgroundColor: "#02486c",
                        }}
                        onClick={this.sendContact}
                      >
                        Submit
                      </button>
                    ) : (
                      <button
                        disabled
                        style={{
                          borderRadius: 60,
                          marginTop:"9%",
                          width: 120,
                          marginBottom: "3%",
                          backgroundColor: "#0885C5",
                        }}
                      >
                        Submit
                      </button>
                    )}
                  </center>
              </div>
            </div>
          </Col>
          <Col className="sl-cl" sm={12} lg={6} md={6} style={{marginTop:"10%"}}>
            <img src={bgCover} alt="background" className="bgImage" />
          </Col>
        </Row>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
      <Footer/>
    </div>
    
    );
  }
}

export default withRouter(contact);
