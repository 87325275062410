import React from "react";
import { withRouter } from "react-router";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import { MdTune } from "react-icons/md";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import "./SearchCard.css";

const filterOptions = [
  { value: "Plot", label: "Plot" },
  { value: "Agri", label: "Agricultural Land" },
  { value: "Flat", label: "Flat" },
  { value: "Commercial ", label: "Commercial" },
  { value: "Independent House ", label: "Independent House" }
];

const cityOptions = [
  { value: "Vizag", label: "Vizag" },
  { value: "Vijayawada", label: "Vijayawada" },
  { value: "Guntur", label: "Guntur" },
  { value: "Amaravathi", label: "Amaravathi" },
  { value: "VIZIANAGARAM", label: "VIZIANAGARAM" },
  { value: "Anandapuram", label: "Anandapuram" },
  { value: "Bengaluru", label: "Bengaluru" },
  { value: "Tirupati", label: "Tirupati" },
  { value: "Visakhapatnam", label: "Visakhapatnam"  },
  { value: "Bhubhuneshwar", label: "Bhubhuneshwar", isDisabled: true },
  { value: "Chennai,", label: "Chennai", isDisabled: true },
  { value: "Hyderabad", label: "Hyderabad", isDisabled: true }
  
];
const priceOptions = [
  { value: "type1", label: "Below 25 Lakhs" },
  { value: "type2", label: "25 Lakhs to 50 Lakhs" },
  { value: "type3", label: "50 Lakhs to 1 Cr" },
  { value: "type4", label: "1 Cr. to 2 Cr" },
  { value: "type5", label: "2 Cr. to 3 Cr." },
  { value: "type6", label: "3 Cr. to 5 Cr." },
{ value: "type7", label: "Above 5 Cr." },
  { value: "all", label: "All" },
 
];
const postOptions = [
  { value: "Myplotpic", label: "Myplotpic" },
  { value: "Owner", label: "Direct Owner" },
{ value: "Agent", label: "Agent" },
  { value: "all", label: "All" },
];

const customStyles = {
  control: (base) => ({
    ...base,
    boxShadow: "none",
  }),
};

class SearchCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterValue: "Plot",
      options: [],
      city: "Vizag",
      price: "type2",
      post: "Myplotpic",
      location: "",
      filterModal: false,
    };
    this.navigatetoAdsPage = this.navigatetoAdsPage.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handleCity = this.handleCity.bind(this);
    this.handlePost = this.handlePost.bind(this);
    this.handlePrice = this.handlePrice.bind(this);
    this.handleLocation = this.handleLocation.bind(this);
  }
  handleLocation(event) {
    this.setState({
      location: event.target.value,
    });
  }
  navigatetoAdsPage = () => {
    this.setState({
      filterModal: false,
    });
    window.localStorage.setItem("cityParam", this.state.city);
    window.localStorage.setItem("filterParam", this.state.filterValue);
    window.localStorage.setItem("postParam", this.state.post);
    window.localStorage.setItem("priceParam", this.state.price);
    window.localStorage.setItem("landmarkParam", this.state.location);
    this.props.history.push("/ads");
  };
  handleFilter = (landType) => {
    this.setState({ filterValue: landType.value });
    //console.log(this.state.filterValue);
  };
  handleCity = (city) => {
    this.setState({ city: city.value });
  };
  handlePost = (post) => {
    this.setState({ post: post.value });
  };
  handlePrice = (price) => {
    this.setState({ price: price.value });
  };
  render() {
    const { options, city, filterValue, price, post } = this.state;
    return (
      <div>
        <p className="smartVisit-title">Smart visit your site</p>
        <p className="buy_txt">Buy</p>
        <>
          <div
            className="new-bx-ads-homestyle"
          >
            <Row>
              <Col md={2} xs={6}>
                <div className="searchCard-dividerRight_First">
                    <Select
                      value={filterOptions.filter(
                        ({ value }) => value === this.state.filterValue
                      )}
                      isSearchable={false}
                      onChange={this.handleFilter}
                      placeholder={<div>Select Type</div>}
                      options={filterOptions}
                      className="selectCity"
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          boxShadow: "none",
                          border: "none",
                          backgroundColor: "transparent",
                          marginTop: -7,
                          textAlign: "left",
                          paddingRight: 20,
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          textAlign: "center",
                          backgroundColor: state.isSelected ? '#00AEEC' : 'inherit',
                        }),
                        indicatorSeparator: (provided, state) => ({
                          ...provided,
                          display:"none"
                        })
                      }}
                    />
                </div>
              </Col>
              <Col md={2} xs={6}>
                <div className="searchCard-dividerRight">
                  <center>
                    <Select
                      value={cityOptions.filter(
                        ({ value }) => value === this.state.city
                      )}
                      onChange={this.handleCity}
                      placeholder={<div>City</div>}
                      isSearchable={false}
                      options={cityOptions}
                      className="selectCity"
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          boxShadow: "none",
                          border: "none",
                          backgroundColor: "transparent",
                          color: "black",
                          marginTop: -7,
                          marginLeft: -38,
                          width:"100%"
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isSelected ? '#00AEEC' : 'inherit',
                        }),
                        indicatorSeparator: (provided, state) => ({
                          ...provided,
                          display:"none"
                        })
                      }}
                    />
                  </center>
                </div>
              </Col>
              <Col md={2} xs={6}>
                <div className="searchCard-dividerRight-Input">
                  <center>
                    <input
                      className="textField-new"
                      type="text"
                      value={this.state.location}
                      placeholder="Location"
                      onChange={this.handleLocation}
                    ></input>
                  </center>
                </div>
              </Col>
              <Col md={2} xs={12} sm={12}>
                <div className="searchCard-dividerRight">
                  <center>
                    <Select
                      value={priceOptions.filter(
                        ({ value }) => value === this.state.price
                      )}
                      onChange={this.handlePrice}
                      placeholder={<div>Price</div>}
                      options={priceOptions}
                      isSearchable={false}
                      className="selectCity"
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          boxShadow: "none",
                          border: "none",
                          backgroundColor: "transparent",
                          color: "#000000",
                          marginTop: -7,
                          marginLeft: -25,
                          width:"100%"
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isSelected ? '#00AEEC' : 'inherit',
                        }),
                        indicatorSeparator: (provided, state) => ({
                          ...provided,
                          display:"none"
                        })
                      }}
                    />
                  </center>
                </div>
              </Col>
              <Col md={2} xs={6}>
                <div className="searchCard-dividerRight">
                  <center>
                    <Select
                      value={postOptions.filter(
                        ({ value }) => value === this.state.post
                      )}
                      onChange={this.handlePost}
                      placeholder={<div>Posted by</div>}
                      options={postOptions}
                      isSearchable={false}
                      className="selectCity"
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          boxShadow: "none",
                          border: "none",
                          backgroundColor: "transparent",
                          color: "#000000",
                          marginTop: -7,
                          marginLeft: -38,
                          width:"100%"
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isSelected ? '#00AEEC' : 'inherit',
                        }),
                        indicatorSeparator: (provided, state) => ({
                          ...provided,
                          display:"none"
                        })
                      }}
                    />
                  </center>
                </div>
              </Col>
              <Col md={2} xs={12}>
                <center>
                  <button className="searchButton-home_searchCard" onClick={() => this.navigatetoAdsPage()}>Search</button>
                </center>
              </Col>
            </Row>
          </div>
          <center>
            <div
              className="new-bx-ads-homestyle-mobile border padding-4"
              style={{ padding: 5 }}
            >
              <div>
                <center>
                  <Row>
                    <Col md={10} sm={10} xs={10}>
                      <input
                        className="textField-new"
                        type="text"
                        value={this.state.location}
                        placeholder="Enter Location"
                        onChange={this.handleLocation}
                      ></input>
                    </Col>
                    <Col md={2} sm={2} xs={2}>
                      <MdTune
                        size={35}
                        className="filterIcon"
                        onClick={() => this.setState({ filterModal: true })}
                      />
                    </Col>
                  </Row>
                </center>
              </div>
              <Modal
                show={this.state.filterModal}
                backdrop="static"
                keyboard={false}
                size="sm-down"
                dialogClassName="search-modal-mobile"
                centered
              >
                <ModalBody>
                  <div style={{ paddingLeft: "10%", paddingRight: "10%", paddingTop: 10, paddingBottom: 10 }}>
                    <div className="searchCard-dividerRight">
                      <center>
                        <Select
                          value={filterOptions.filter(
                            ({ value }) => value === this.state.filterValue
                          )}
                          onChange={this.handleFilter}
                          placeholder={<div>Select Type</div>}
                          options={filterOptions}
                          className="selectCity"
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              backgroundColor: "transparent",
                              marginTop: -5,
                            }),
                          }}
                        />
                      </center>
                    </div>
                    <br />
                    <div className="searchCard-dividerRight">
                      <center>
                        <Select
                          value={cityOptions.filter(
                            ({ value }) => value === this.state.city
                          )}
                          onChange={this.handleCity}
                          placeholder={<div>City/Town</div>}
                          options={cityOptions}
                          className="selectCity"
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              backgroundColor: "transparent",
                              color: "black",
                              marginTop: -5,
                            }),
                          }}
                        />
                      </center>
                    </div>
                    <br />
                    <div className="searchCard-dividerRight">
                      <center>
                        <Select
                          value={priceOptions.filter(
                            ({ value }) => value === this.state.price
                          )}
                          onChange={this.handlePrice}
                          placeholder={<div>Price</div>}
                          options={priceOptions}
                          className="selectCity"
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              backgroundColor: "transparent",
                              color: "#000000",
                              marginTop: -5,
                            }),
                          }}
                        />
                      </center>
                    </div>
                    <br />
                    <div className="searchCard-dividerRight">
                      <center>
                        <Select
                          value={postOptions.filter(
                            ({ value }) => value === this.state.post
                          )}
                          onChange={this.handlePost}
                          placeholder={<div>Posted by</div>}
                          options={postOptions}
                          className="selectCity"
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              backgroundColor: "transparent",
                              color: "#000000",
                              marginTop: -5,
                            }),
                          }}
                        />
                      </center>
                    </div>
                    <br />
                    <center>
                      <Row>
                        <Col md={6} xs={6} sm={6}>
                          <Button
                            variant="primary"
                            id="searchButton-home-white"
                            onClick={() => this.setState({ filterModal: false })}
                          >
                            Close
                          </Button>
                        </Col>
                        <Col md={6} xs={6} sm={6}>
                          <Button
                            variant="primary"
                            id="searchButton-home"
                            onClick={this.navigatetoAdsPage}
                          >
                            Search
                          </Button>
                        </Col>
                      </Row>
                    </center>
                  </div>
                </ModalBody>
              </Modal>
            </div>
          </center>
        </>
      </div>
    );
  }
}

export default withRouter(SearchCard);
