import React from "react";
import { withRouter } from "react-router-dom";
import "./refund-policy.css";
import Footer from "../../../components/Footer/footer";
import Navbar from "../../../components/NavBar/index";
import {
  FloatingMenu,
  MainButton,
  ChildButton,
} from 'react-floating-button-menu';
import { BsPlusLg, BsWhatsapp} from "react-icons/bs";
import { MdClose } from "react-icons/md";

class refund_policy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFABOpen: false
    };
  }
  componentDidMount(){
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }
  render() {
    return (
    <div>
      <div>
        <Navbar />
        <div className="fab-btn-hvr">
          <FloatingMenu
            slideSpeed={500}
            direction="up"
            spacing={8}
            isOpen={this.state.isFABOpen}
          >
            <MainButton
              iconResting={<BsPlusLg size={22}/>}
              iconActive={<MdClose size={28} className="GrClose-icons"/>}
              style={{backgroundColor:"#00476b"}}
              onClick={() => this.setState({ isFABOpen: !this.state.isFABOpen })}
              size={56}
            />
            <ChildButton
              icon={<BsWhatsapp size={20} className="BsWhatsapp-icons" />}
              style={{backgroundColor:"#00476b"}}
              size={40}
              onClick={() => window.open("https://wa.me/917249556789?text=Hello", '_blank')}
            />
          </FloatingMenu>
        </div>
      </div>
      <div className="termscontainer">
        <div>
          <p className="terms-Title">Payment & Refund clause</p>
          <ul>
            <li>Services ordered and not delivered by the ‘Company’ with in 10 days from the payment date will refund to the customer within 15 days from the Payment date.</li>
            <li>Refund amount must be restricted to the amount paid by the customer to the company and no interest will bear by the company in any circumstances.</li>
            <li>No incidental amounts or damages caused due to non-execution of the order will be refunded.</li>
            <li>The Subscription Fees shall be paid by the User on demand. In case the user disputes the same for any reason whatsoever, he shall make the payment towards the Subscription Fees accrued subject to the decision of the Company on the dispute. In the event of Company's deciding the dispute in the User's favour, the Company shall refund to the User any excess amount paid by the User free of interest.</li>
            <li>Payments made to the Company through iOS app shall not be refundable under any circumstances, including but not limited to the termination of this Agreement for whatever reason.</li>
            <li>The Company offers no guarantees whatsoever for the accuracy or timeliness of the refunds reaching the Subscribers card/bank accounts. This is on account of the multiplicity of organizations involved in processing of online transactions, the problems with Internet infrastructure currently available and working days/holidays of financial institutions.</li>
          </ul>
        </div>
        <div>
          <p className="terms-Title">Payment & Refund clause</p>
          <ul>
            <li>Company shall reserve the exclusive right to cancel any order placed by the customer. The order amount collected from the customers charges payable to the Customer shall be laid down in the refund policy.</li>
          </ul>
        </div>
        <div>
          <p className="terms-Title">Payment & Refund clause</p>
          <ul>
            <li>Payment for the services offered shall be on 100% advance basis.</li>
            <li>Payment for service once subscribed to by the subscriber, is not refundable and any amount paid shall stand appropriated.</li>
            <li>Refund if any will be at the sole discretion of the Company only.</li>
            <li>The Company offers no guarantees whatsoever for the accuracy or timeliness of the refunds reaching the Subscribers card/bank accounts. This is on account of the multiplicity of organizations involved in processing of online transactions, the problems with Internet infrastructure currently available and working days/holidays of financial institutions.</li>
          </ul>
        </div>
      </div>
      <Footer/>
    </div>
    
    );
  }
}

export default withRouter(refund_policy);
