import React from "react";
import { withRouter } from "react-router-dom";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { IoClose } from "react-icons/io5";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import { ToastContainer, toast } from "react-toastify";
import OtpInput from "react-otp-input";
import { TbSpeakerphone } from "react-icons/tb";
import {
  AiOutlineHome,
  AiOutlineCodeSandbox,
  AiOutlineBell,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { BiSupport, BiInfoCircle, BiUserCircle, BiLogIn } from "react-icons/bi";

import "./custom.scss";
import "./sidebar_styles.css";
import "react-toastify/dist/ReactToastify.css";
import logo from "../../assets/images/Frame.png";

class SideBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sideBar: this.props.sideBarShow,
    };
  }
  clearAsyncStorage = async () => {
    localStorage.clear();
    window.location.reload();
  };
  toggleButton = () => {
    this.setState({
      sideBar: !this.state.sideBar,
    });
  };
  navigateHome = () => {
    this.props.history.push("/");
    this.toggleButton();
  };
  navigateAds = () => {
    this.props.history.push("/ads");
    this.toggleButton();
  };
  navigateContact = () => {
    this.props.history.push("/contact");
    this.toggleButton();
  };
  navigateAbout = () => {
    this.props.history.push("/about-us");
    this.toggleButton();
  };
  navigateServices = () => {
    this.props.history.push("/services");
    this.toggleButton();
  };
  navigateRegister = () => {
    this.props.history.push("/register");
    this.toggleButton();
  };
  navigateLogin = () => {
    this.props.history.push("/login");
    this.toggleButton();
  };
  navigateNotifications = () => {
    this.props.history.push("/notifications");
    this.toggleButton();
  };
  navigateRequest = () => {
    this.props.history.push("/create-order");
    this.toggleButton();
  };
  navigateStatus = () => {
    this.props.history.push("/status");
    this.toggleButton();
  };
  render() {
    return (
      <>
        {this.state.sideBar ? (
          <ProSidebar className="minSidebar">
            <center class="aligner">
              <img src={logo} className="menu-logo" />
            </center>
            <Menu iconShape="square">
              <MenuItem icon={<AiOutlineHome size={25} color={"#fff"} />}>
                <span onClick={this.navigateHome} className="sidebar-item-txt">
                  Home
                </span>
              </MenuItem>
              <MenuItem icon={<TbSpeakerphone size={25} color={"#fff"} />}>
                <span onClick={this.navigateAds} className="sidebar-item-txt">
                  Listings
                </span>
              </MenuItem>
              <MenuItem icon={<BiSupport size={25} color={"#fff"} />}>
                <span
                  onClick={this.navigateContact}
                  className="sidebar-item-txt"
                >
                  Contact
                </span>
              </MenuItem>
              <MenuItem icon={<BiInfoCircle size={25} color={"#fff"} />}>
                <span onClick={this.navigateAbout} className="sidebar-item-txt">
                  About
                </span>
              </MenuItem>
              <MenuItem
                icon={<AiOutlineCodeSandbox size={25} color={"#fff"} />}
              >
                <span
                  onClick={this.navigateServices}
                  className="sidebar-item-txt"
                >
                  Services
                </span>
              </MenuItem>
              {localStorage.getItem("userData") ? (
                <MenuItem icon={<AiOutlineBell size={25} color={"#fff"} />}>
                  <span
                    onClick={this.navigateNotifications}
                    className="sidebar-item-txt"
                  >
                    Notifications
                  </span>
                </MenuItem>
              ) : (
                <></>
              )}
              {localStorage.getItem("userData") ? (
                <MenuItem icon={<AiOutlineBell size={25} color={"#fff"} />}>
                  <span
                    onClick={this.navigateStatus}
                    className="sidebar-item-txt"
                  >
                    Order Status
                  </span>
                </MenuItem>
              ) : (
                <></>
              )}
              <MenuItem icon={<BiLogIn size={25} color={"#fff"} />}>
                {localStorage.getItem("userData") ? (
                  <span
                    onClick={this.navigateRequest}
                    className="sidebar-item-txt"
                  >
                    Request
                  </span>
                ) : (
                  <span
                    onClick={this.navigateLogin}
                    className="sidebar-item-txt"
                  >
                    Login
                  </span>
                )}
              </MenuItem>
              <MenuItem icon={<BiUserCircle size={25} color={"#fff"} />}>
                {localStorage.getItem("userData") ? (
                  <span
                    onClick={this.clearAsyncStorage}
                    className="sidebar-item-txt"
                  >
                    Log Out
                  </span>
                ) : (
                  <span
                    onClick={this.navigateRegister}
                    className="sidebar-item-txt"
                  >
                    Create Account
                  </span>
                )}
              </MenuItem>
            </Menu>
          </ProSidebar>
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default withRouter(SideBar);
