import React, { useRef, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import ModalBody from 'react-bootstrap/ModalBody';
import { Col, Row, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { baseurl } from '../../utils/BaseUrl';

const ImageEditor = ({ baseImageUrl,userId,postID, width, height, showModal, setShowModal }) => {
  const canvasRef = useRef(null);
  const [logoImageUrl, setLogoImageUrl] = useState(null);
  const [logoImageFile, setLogoImageFile] = useState(null); // New state to store the uploaded logo image file
  const [logoWidth, setLogoWidth] = useState(100);
  const [logoHeight, setLogoHeight] = useState(100);
  const [logoX, setLogoX] = useState(50);
  const [logoY, setLogoY] = useState(50);
  const [text, setText] = useState('Enter your text');
  const [textColor, setTextColor] = useState('white');
  const [textSize, setTextSize] = useState(30);
  const [textX, setTextX] = useState(60);
  const [textY, setTextY] = useState(height - 50);

  useEffect(() => {
    if (!showModal || !canvasRef.current) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    canvas.width = width;
    canvas.height = height;

    const baseImage = new Image();
    baseImage.src = baseImageUrl;

    baseImage.onload = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(baseImage, 0, 0, width, height);

      if (logoImageUrl) {
        const logoImage = new Image();
        logoImage.src = logoImageUrl;
        logoImage.onload = () => {
          ctx.drawImage(logoImage, logoX, logoY, logoWidth, logoHeight);
          ctx.font = `${textSize}px Arial`;
          ctx.fillStyle = textColor;
          ctx.fillText(text, textX, textY);
        };
      } else {
        ctx.font = `${textSize}px Arial`;
        ctx.fillStyle = textColor;
        ctx.fillText(text, textX, textY);
      }
    };
  }, [showModal, baseImageUrl, width, height, logoImageUrl, logoWidth, logoHeight, logoX, logoY, text, textColor, textSize, textX, textY]);

  const handleLogoUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setLogoImageFile(file); // Store the uploaded file
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogoImageUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDownload = async () => {
    try {
      const formData = new FormData();
      formData.append('baseImageUrl', baseImageUrl); // Make sure this is a valid URL
      formData.append('logoImage', logoImageUrl); // Ensure logoImageUrl is the file, not just a URL
      formData.append('text', text);
      formData.append('textColor', textColor);
      formData.append('textSize', textSize);
      formData.append('logoWidth', logoWidth);
      formData.append('logoHeight', logoHeight);
      formData.append('logoX', logoX);
      formData.append('logoY', logoY);
      formData.append('textX', textX);
      formData.append('textY', textY);
      formData.append('width', width);
      formData.append('height', height);

      formData.append('userId', userId);
      formData.append('postID', postID);
      const response = await fetch(baseurl + 'api/process-image', {
          method: 'POST',
          body: formData,
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error processing image:', errorText);
        throw new Error('Failed to process image');
      }
  
      // Handle image download
      const imageBlob = await response.blob();
      const url = URL.createObjectURL(imageBlob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'edited-image.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading image:', error);
    }
  };
  

  return (
    <>
      {/* Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)} fullscreen>
        <Modal.Header closeButton>
          <Modal.Title>Poster Editor</Modal.Title>
        </Modal.Header>
        <ModalBody>
          <Row>
            <Col sm={12} md={6} style={{ height: '100vh', overflow: 'auto' }}>
              <canvas ref={canvasRef} style={{ border: '1px solid black', width: '100%' }}></canvas>
            </Col>
            <Col sm={12} md={6} style={{ height: '100vh', overflow: 'auto' }}>
              {/* Customization Options */}
              <Form className='row'>
                <h5>Logo Settings</h5>
                <Form.Group className='col-6'>
                  <Form.Label>Upload Logo</Form.Label>
                  <Form.Control type="file" onChange={handleLogoUpload} />
                </Form.Group>
                <Form.Group className='col-6'>
                  <Form.Label>Logo Width</Form.Label>
                  <Form.Control
                    type="number"
                    value={logoWidth}
                    onChange={(e) => setLogoWidth(parseInt(e.target.value))}
                  />
                </Form.Group>
                <Form.Group className='col-6'>
                  <Form.Label>Logo Height</Form.Label>
                  <Form.Control
                    type="number"
                    value={logoHeight}
                    onChange={(e) => setLogoHeight(parseInt(e.target.value))}
                  />
                </Form.Group>
                <Form.Group className='col-6'>
                  <Form.Label>Logo X Position</Form.Label>
                  <Form.Control
                    type="range"
                    min="0"
                    max={width - logoWidth}
                    value={logoX}
                    onChange={(e) => setLogoX(parseInt(e.target.value))}
                  />
                </Form.Group>
                <Form.Group className='col-6'>
                  <Form.Label>Logo Y Position</Form.Label>
                  <Form.Control
                    type="range"
                    min="0"
                    max={height - logoHeight}
                    value={logoY}
                    onChange={(e) => setLogoY(parseInt(e.target.value))}
                  />
                </Form.Group>

                <h5>Text Settings</h5>
                <Form.Group className='col-6'>
                  <Form.Label>Text</Form.Label>
                  <Form.Control
                    type="text"
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className='col-6'>
                  <Form.Label>Text Color</Form.Label>
                  <Form.Control
                    type="color"
                    value={textColor}
                    onChange={(e) => setTextColor(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className='col-6'>
                  <Form.Label>Text Size</Form.Label>
                  <Form.Control
                    type="number"
                    value={textSize}
                    onChange={(e) => setTextSize(parseInt(e.target.value))}
                  />
                </Form.Group>
                <Form.Group className='col-6'>
                  <Form.Label>Text X Position</Form.Label>
                  <Form.Control
                    type="range"
                    min="0"
                    max={width - 100}
                    value={textX}
                    onChange={(e) => setTextX(parseInt(e.target.value))}
                  />
                </Form.Group>
                <Form.Group className='col-6'>
                  <Form.Label>Text Y Position</Form.Label>
                  <Form.Control
                    type="range"
                    min="0"
                    max={height}
                    value={textY}
                    onChange={(e) => setTextY(parseInt(e.target.value))}
                  />
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </ModalBody>
        <Modal.Footer>
          <button type="button" className="btn bg-dark" onClick={() => setShowModal(false)}>
            Close
          </button>
          <button type="button" className="btn bg-dark" onClick={handleDownload}>
            Download Image
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ImageEditor;
