// utils/encryption.js
import CryptoJS from 'crypto-js';

const secretKey = 'your-secret-key'; // Use a strong secret key

export const encryptData = (data) => {
    const jsonString = JSON.stringify(data);
    return CryptoJS.AES.encrypt(jsonString, secretKey).toString();
};

export const decryptData = (encryptedData) => {
      // Log the encrypted data
    
    // Attempt to decrypt
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    
     // Log the decrypted data
    
    // Check if the decrypted data is valid before parsing
    if (!decryptedData) {
        console.error('Decryption failed, no valid UTF-8 string returned.');
        return null; // or handle the error as appropriate
    }

    try {
        return JSON.parse(decryptedData);
    } catch (error) {
        console.error('Error parsing JSON:', error);
        return null; // Handle parsing error
    }
};

