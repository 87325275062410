import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import { IoClose } from "react-icons/io5";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './login.css';
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const LoginModal = ({ show, onHide }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [errors, setErrors] = useState({});
  const [rememberMe, setRememberMe] = useState(false);

  const handleEmailChange = (event) => {
    const value = event.target.value;
    const re = /\S+@\S+\.\S+/;
    setEmail(value);
    setErrors((prev) => ({
      ...prev,
      email: re.test(value) ? null : "Invalid Email",
    }));
  };

  const handlePasswordChange = (event) => {
    const value = event.target.value;
    setPassword(value);
    setErrors((prev) => ({
      ...prev,
      password: value.length > 7 ? null : "Minimum 8 characters",
    }));
  };

  const handlePhoneChange = (event) => {
    const value = event.target.value;
    setPhone(value);
    setErrors((prev) => ({
      ...prev,
      phone: value.length === 10 ? null : "Must be 10 digits",
    }));
  };

  const toastFunction = (message, type) => {
    toast[type](message, {
      position: "bottom-center",
      autoClose: 2700,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const handleLogin = async () => {
    if (!errors.email && !errors.password && !errors.phone) {
      toastFunction("Loading ..", "info");
      // Assume you have an API endpoint for login
      const response = await fakeLoginAPI({ email, password, phone });
      if (response.success) {
        toastFunction("Authenticated", "success");
        onHide(); // Close the modal on success
      } else {
        toastFunction("Authentication Error", "error");
      }
    }
  };

  return (
    <Modal show={show} backdrop="static" centered onHide={onHide}>
      <ModalBody>
        <div>
          <IoClose size={25} className="closeIcon" onClick={onHide} />
          <center>
            <p className="loginModalTxt" style={{ color: "#02486c" }}>
              Login
            </p>
          </center>
          <p className="loginModal_txt">Phone :</p>
          <input
            className="loginInput"
            placeholder="Phone"
            value={phone}
            type="number"
            maxLength="10"
            onChange={handlePhoneChange}
          />
          {errors.phone && <p className="loginErrorTxt">{errors.phone}</p>}
          <Row>
            <Col sm={5} xs={5} className="divider-Margin">
              <hr className="left-hr" />
            </Col>
            <Col sm={2} xs={2} className="divider-Margin">
              <center>or</center>
            </Col>
            <Col sm={5} xs={5} className="divider-Margin">
              <hr className="left-hr" />
            </Col>
          </Row>
          <p className="loginModal_txt">Email :</p>
          <input
            className="loginInput"
            type="text"
            placeholder="Email"
            value={email}
            onChange={handleEmailChange}
          />
          {errors.email && <p className="loginErrorTxt">{errors.email}</p>}

          <p className="loginModal_txt">Password :</p>
          <input
            className="loginInput"
            type="password"
            placeholder="Password"
            value={password}
            onChange={handlePasswordChange}
          />
          {errors.password && <p className="loginErrorTxt">{errors.password}</p>}

          <Row style={{ marginTop: "6%" }}>
            <Col md={1} xs={1} sm={1}>
              <input
                type="checkbox"
                onChange={() => setRememberMe(!rememberMe)}
                checked={rememberMe}
              />
            </Col>
            <Col md={4} xs={5} sm={5}>
              <label className="login_txt">Remember me</label>
            </Col>
            <Col md={4} xs={5} sm={5} className="text-end">
              <span
                className="forgotPass-hover"
                onClick={() => {
                  // Handle forgot password logic here
                }}
              >
                Forgot Password?
              </span>
            </Col>
          </Row>

          <Row style={{ marginTop: "6%" }}>
            <Col md={12} xs={12} sm={12} className="text-end">
              <Link to="/register" className='signupLink' style={{ color: 'black', textDecoration: 'underline' }}>
                Sign up for a new account now!
              </Link>
            </Col>
          </Row>

          <center>
            <button
              style={{
                borderRadius: 60,
                backgroundColor: "#00476b",
                marginBottom: "5%",
                width: 180,
              }}
              type="button"
              onClick={handleLogin}
            >
              Login
            </button>
          </center>
          <ToastContainer />
        </div>
      </ModalBody>
    </Modal>
  );
};

// Fake API function for demonstration
const fakeLoginAPI = async ({ email, password, phone }) => {
  return new Promise((resolve) =>
    setTimeout(() => resolve({ success: true }), 1000)
  );
};

export default LoginModal;
