import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';
import "./Navbar.css";


export const Nav = styled.nav`
background: transparent;
height: 80px;

padding-left: 3%;
padding-right: 5%;
padding-top: 10px;
`;

export const NavLink = styled(Link)`
color: #000000;
text-decoration: none;
padding: 0 1rem;
height: 100%;
cursor: pointer;
&.active {
	color: #fff;
}
`;
export const NavMenu = styled.div`
align-items: center;
@media screen and (max-width: 768px) {
	display: none;
}
`;

