import React from "react";
import { withRouter } from "react-router";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import { MdTune } from "react-icons/md";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import "./SearchCard.css";
 
 

const QuotesSearch = () => {
  return (
  
    <>
      <div
        className="new-bx-ads-homestyle"
      >
        <Row>
          
         
          <Col md={8} xs={8}>
            <div className="searchCard-dividerRight-Input">
           
                <input
                  className="textField-new"
                  type="text"
                  
                  placeholder="Search Quotes"
                   
                ></input>
              
            </div>
          </Col>
         
          <Col md={4} xs={4}>
            <center>
              <button className="searchButton-home_searchCard"  >Search</button>
            </center>
          </Col>
        </Row>
      </div>
     
    </>
  
  )
}

export default QuotesSearch
